import React from "react";
import Pagination from "./pagination";
import Posts from "./posts";
import Sidebar from "./sidebar";

export default function Grid() {
  return (
    <section className="blog-grid small-section">
      <div className="blog-grid--blog-wrapper p-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 sticky-content">
              <Posts></Posts>
              <Pagination></Pagination>
            </div>
            <Sidebar></Sidebar>
          </div>
        </div>
      </div>
    </section>
  );
}
