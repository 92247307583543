import React from "react";

export default function Sidebar() {
  return (
    <div className="col-xl-3 sticky-sidebar">
      <div className="sidebar-wrapper right-sidebar">
        <aside className="sidebar-wrapper--sidebar">
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Search</h6>
            </div>
            <div className="sidebar-widget--search">
              <form className="search-form">
                <input type="text" placeholder="Input some text ..." />
                <button type="button">
                  <i className="fa fa-search double-flash"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Recent Posts</h6>
            </div>
            <ul className="sidebar-widget--post-list">
              <li className="post-list--post">
                <a href="index.html">
                  <div className="post--img-wrapper">
                    <img
                      src="http://via.placeholder.com/1920x1280"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </a>
                <div className="post--post-body">
                  <h6 className="post-body--post-title">
                    <a href="index.html">Nothing is impossible</a>
                  </h6>
                  <div className="post-body--description-box">
                    <p className="description-box--date">
                      17 July 2019,{" "}
                      <a href="index.html" className="animation-link">
                        Jay Hart
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li className="post-list--post">
                <a href="index.html">
                  <div className="post--img-wrapper">
                    <img
                      src="http://via.placeholder.com/1920x1280"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </a>
                <div className="post--post-body">
                  <h6 className="post-body--post-title">
                    <a href="index.html">What lies behind you</a>
                  </h6>
                  <div className="post-body--description-box">
                    <p className="description-box--date">
                      18 May 2019,{" "}
                      <a href="index.html" className="animation-link">
                        Mike Snow
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li className="post-list--post">
                <a href="index.html">
                  <div className="post--img-wrapper">
                    <img
                      src="http://via.placeholder.com/1920x1280"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </a>
                <div className="post--post-body">
                  <h6 className="post-body--post-title">
                    <a href="index.html">The spirit of design</a>
                  </h6>
                  <div className="post-body--description-box">
                    <p className="description-box--date">
                      5 June 2019,{" "}
                      <a href="index.html" className="animation-link">
                        Emily Smith
                      </a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">About Author</h6>
            </div>
            <div className="sidebar-widget--author">
              <div className="author--img-wrapper">
                <a href="index.html">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectet adipisicing elit. A ad
                cupiditate dolor dolores expedita similique sit ullam
              </p>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Tag Cloud</h6>
            </div>
            <div className="sidebar-widget--tag-cloud">
              <a href="index.html" className="tag-cloud--tag">
                Modern
              </a>
              <a href="index.html" className="tag-cloud--tag">
                Design
              </a>
              <a href="index.html" className="tag-cloud--tag">
                Frontend
              </a>
              <a href="index.html" className="tag-cloud--tag">
                HTML
              </a>
              <a href="index.html" className="tag-cloud--tag">
                Photo
              </a>
              <a href="index.html" className="tag-cloud--tag">
                Gallery
              </a>
              <a href="index.html" className="tag-cloud--tag">
                Solution
              </a>
              <a href="index.html" className="tag-cloud--tag">
                Smart
              </a>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Image</h6>
            </div>
            <div className="sidebar-widget--image">
              <a
                href="http://via.placeholder.com/1920x1280"
                className="image-popup"
              >
                <img
                  src="http://via.placeholder.com/1920x1280"
                  alt=""
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Categories</h6>
            </div>
            <ul className="sidebar-widget--categories">
              <li>
                <a href="#">Blog</a>
                <span>25</span>
              </li>
              <li>
                <a href="index.html">Feature</a>
                <span>12</span>
              </li>
              <li>
                <a href="index.html">Design</a>
                <span>47</span>
              </li>
              <li>
                <a href="index.html">Technology</a>
                <span>10</span>
              </li>
              <li>
                <a href="index.html">Nature</a>
                <span>08</span>
              </li>
              <li>
                <a href="index.html">Science</a>
                <span>15</span>
              </li>
              <li>
                <a href="index.html">Branding</a>
                <span>18</span>
              </li>
              <li>
                <a href="index.html">Books</a>
                <span>23</span>
              </li>
            </ul>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Quote</h6>
            </div>
            <div className="sidebar-widget--quote">
              <i className="fas fa-quote-left"></i>
              <h6>
                “Live as if you were to die tomorrow. Learn as if you were to
                live forever.”
              </h6>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Dribble</h6>
            </div>
            <div className="sidebar-widget--social-networks">
              <div className="social-networks--dribble-widget">
                <a href="https://dribbble.com/" className="gallery--image-item">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                  <i className="fab fa-dribbble"></i>
                </a>
                <a href="https://dribbble.com/" className="gallery--image-item">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                  <i className="fab fa-dribbble"></i>
                </a>
                <a href="https://dribbble.com/" className="gallery--image-item">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                  <i className="fab fa-dribbble"></i>
                </a>
                <a href="https://dribbble.com/" className="gallery--image-item">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                  <i className="fab fa-dribbble"></i>
                </a>
                <a href="https://dribbble.com/" className="gallery--image-item">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                  <i className="fab fa-dribbble"></i>
                </a>
                <a href="https://dribbble.com/" className="gallery--image-item">
                  <img
                    src="http://via.placeholder.com/1920x1280"
                    alt=""
                    className="img-fluid"
                  />
                  <i className="fab fa-dribbble"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Video</h6>
            </div>
            <div
              className="sidebar-widget--video bg-position-center bg-size-cover"
              style={{
                backgroundImage: "url('http://via.placeholder.com/1920x1280')",
              }}
            >
              <div className="video-wrapper-type-3">
                <a
                  className="video-toggle-type-3 video-popup"
                  href="#video-post-1"
                >
                  <div className="play-btn-scale">
                    <i className="fa fa-play"></i>
                  </div>
                </a>
                <div
                  id="video-post-1"
                  className="white-popup-block mfp-hide mfp-fade"
                >
                  <div className="modal-video-box">
                    <iframe
                      src="https://www.youtube.com/embed/iGpuQ0ioPrM"
                      allowfullscreen
                    ></iframe>
                    <button
                      title="Close (Esc)"
                      type="button"
                      className="mfp-close"
                    >
                      ×
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar--sidebar-widget">
            <div className="sidebar-widget--widget-title">
              <h6 className="title">Archive</h6>
            </div>
            <ul className="sidebar-widget--archive">
              <li>
                <a href="index.html">January</a>
                <span>(16)</span>
              </li>
              <li>
                <a href="index.html">February</a>
                <span>(12)</span>
              </li>
              <li>
                <a href="index.html">March</a>
                <span>(36)</span>
              </li>
              <li>
                <a href="index.html">April</a>
                <span>(04)</span>
              </li>
              <li>
                <a href="index.html">May</a>
                <span>(95)</span>
              </li>
              <li>
                <a href="index.html">June</a>
                <span>(15)</span>
              </li>
              <li>
                <a href="index.html">July</a>
                <span>(36)</span>
              </li>
              <li>
                <a href="index.html">August</a>
                <span>(23)</span>
              </li>
              <li>
                <a href="index.html">September</a>
                <span>(23)</span>
              </li>
              <li>
                <a href="index.html">October</a>
                <span>(74)</span>
              </li>
              <li>
                <a href="index.html">November</a>
                <span>(17)</span>
              </li>
              <li>
                <a href="index.html">December</a>
                <span>(43)</span>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
}
