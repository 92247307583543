import React from "react";

export default function Pagination() {
  return (
    <div className="blog-wrapper--blog-pagination">
      <ul className="blog-pagination--pagination">
        <li>
          <a href="index.html">
            <i className="fa fa-angle-left"></i>
          </a>
        </li>
        <li className="pagination--active">
          <a href="index.html">1</a>
        </li>
        <li>
          <a href="index.html">2</a>
        </li>
        <li>
          <a href="index.html">3</a>
        </li>
        <li>
          <a href="index.html">
            <i className="fa fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}
