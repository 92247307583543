import * as React from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import Grid from "../components/blog/grid";

const Blog = () => {
  const breadcrumbs = [
    { link: "/", text: "Home" },
    { link: "/blog", text: "Blog" },
  ];

  return (
    <Layout>
      <Header title="Blog" breadcrumbs={breadcrumbs}></Header>
      <Grid></Grid>
    </Layout>
  );
};

export default Blog;
