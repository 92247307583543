import React from "react";

export default function Posts() {
  return (
    <div
      className="blog-wrapper--grid grid sidebar-layout"
      data-grid-layout-mode="masonry"
    >
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner">
          <div className="grid-inner--post-tag">
            <a href="index.html" className="post-tag--tag">
              <i className="fa fa-tag icon-tag"></i>
              Business
            </a>
          </div>
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div
                className="post-content--card-head bg-size-cover bg-position-center"
                style={{
                  backgroundImage:
                    "url('http://via.placeholder.com/1920x1280')",
                }}
              ></div>
              <div className="post-content--card-body">
                <a href="blog-post-layout-1-sidebar-layout.html">
                  <h5>The Great Content Debate</h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>5 June, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>36
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>76
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner">
          <div className="grid-inner--post-tag">
            <a href="index.html" className="post-tag--tag">
              <i className="fa fa-tag icon-tag"></i>
              Happiness
            </a>
          </div>
          <div className="swiper-container swiper-post">
            <div className="swiper-wrapper">
              <div
                className="swiper-slide"
                style={{
                  backgroundImage:
                    "url('http://via.placeholder.com/1920x1280')",
                }}
              ></div>
              <div
                className="swiper-slide"
                style={{
                  backgroundImage:
                    "url('http://via.placeholder.com/1920x1280')",
                }}
              ></div>
              <div
                className="swiper-slide"
                style={{
                  backgroundImage:
                    "url('http://via.placeholder.com/1920x1280')",
                }}
              ></div>
            </div>
            <div className="swiper-button-next-post">
              <i className="fa fa-angle-right"></i>
            </div>
            <div className="swiper-button-prev-post">
              <i className="fa fa-angle-left"></i>
            </div>
          </div>
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-2-sidebar-layout.html">
                  <h5>4 Ways to Keep Inspired</h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>12 May, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>56
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>98
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner video-post">
          <div className="video-post--video-wrapper">
            <div className="post-video-wrapper header-wrapper--youtube-video">
              <iframe
                src="https://www.youtube.com/embed/https://youtu.be/fCPEAq6FMF8?list=PLGmxyVGSCDKvmLInHxJ9VdiwEb82Lxd2Eorigin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
                allowfullscreen
                allowtransparency
                allow="autoplay"
                title="placeholder"
              ></iframe>
            </div>
          </div>
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-1-sidebar-layout.html">
                  <h5>Blear moon ambient</h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>14 June, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>47
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>83
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner">
          <div className="grid-inner--post-tag">
            <a href="index.html" className="post-tag--tag">
              <i className="fa fa-tag icon-tag"></i>
              Business
            </a>
          </div>
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div
                className="post-content--card-head bg-size-cover bg-position-center"
                style={{
                  backgroundImage:
                    "url('http://via.placeholder.com/1920x1280')",
                }}
              ></div>
              <div className="post-content--card-body">
                <a href="blog-post-layout-1-full-width.html">
                  <h5>High-Quality Photography</h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>12 November, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>14
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>36
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div
          className="grid-item--grid-inner post-image bg-position-center bg-size-cover overlay black-overlay"
          style={{
            backgroundImage: "url('http://via.placeholder.com/1920x1280')",
          }}
        >
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-1-full-width.html">
                  <h5 className="white-color">The 2019 Starter’s Guide</h5>
                </a>
                <p className="border-bottom-simple white-color">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span className="white-color">
                      <i className="fas fa-calendar-alt"></i>20 February, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span className="white-color">
                      <i className="fas fa-comment-alt"></i>21
                    </span>
                    <span className="white-color">
                      <i className="fas fa-heart"></i>62
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner aside-post">
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-2-full-width.html">
                  <h5>Turn Your Sketch with Keito</h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>13 April, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>82
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>91
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner link-post">
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-1-full-width.html">
                  <h5>
                    <i className="fas fa-link"></i>10 tips for your startup
                  </h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>21 May, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>45
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>39
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner quote-post">
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <h5>
                  "You know you're in love when you can't fall asleep because
                  reality is finally better than your dreams."
                </h5>
                <div className="card-body--author">
                  <a href="index.html">- ThemeHiegger</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quote-post--quote-icon">
          <i className="fas fa-quote-right"></i>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner audio-post">
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-2-full-width.html">
                  <h5>The Most Underrated ’90s</h5>
                </a>
                <audio controls className="border-bottom-simple">
                  <source src="audio/myMusic.mp3" type="audio/mp3" />
                  <p>
                    Your browser doesn't support HTML5 audio. Here is a{" "}
                    <a href="index.html">link to the audio</a> instead.
                  </p>
                </audio>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>4 January, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>57
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>72
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="grid--grid-item">
        <div className="grid-item--grid-inner sticky-post">
          <div className="grid-inner--post-body">
            <div className="post-body--post-content">
              <div className="post-content--card-body">
                <a href="blog-post-layout-2-sidebar-layout.html">
                  <h5>Street Collection n 2019</h5>
                </a>
                <p className="border-bottom-simple">
                  Lorem ipsum dolor sit amet, consecte adipisicing elit.
                  Adipisci alias aliquam aut dolorem doloribus nisi nobis nulla
                  possimus, quibusdam aliqua mquisquam
                </p>
                <div className="card-body--card-footer">
                  <div className="card-footer--date">
                    <span>
                      <i className="fas fa-calendar-alt"></i>13 April, 2019
                    </span>
                  </div>
                  <div className="card-footer--post-general">
                    <span>
                      <i className="fas fa-comment-alt"></i>82
                    </span>
                    <span>
                      <i className="fas fa-heart"></i>91
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-post--sticky-icon">
            <i className="fas fa-thumbtack"></i>
          </div>
        </div>
      </article>
    </div>
  );
}
